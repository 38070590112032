/**
 * Student Profile JS.
 *
 * @package LifterLMS_Social_Learning/Scripts
 *
 * @since 1.0.0
 * @version 1.6.0
 */

( function( $ ) {
	/**
	 * UI & UX for Student Profiles.
	 *
	 * @since 1.0.0
	 * @since 1.1.0 Unknown.
	 * @since 1.6.0 Added the ability to remove an avatar or cover image through a context menu.
	 */
	var Profile = function() {

		var $modal = $( '#llms-sl-upload-modal' );

		/**
		 * Bind DOM events.
		 *
		 * @since 1.0.0
		 * @since 1.1.0 Unknown.
		 * @since 1.6.0 Change the setup and event binding of the cover image and avatar.
		 *
		 * @return void
		 */
		function bind() {

			$modal.iziModal();

			var updateCoverButton = $( '#llms-sl-upload-cover' );
			updateCoverButton.attr( 'data-image-type', 'cover' );
			updateCoverButton.on( 'click', onClickImageButton );
			updateCoverButton.on( 'focusout', onFocusOutImageButton );

			var updateAvatarButton = $( '#llms-sl-upload-avatar' );
			updateAvatarButton.attr( 'data-image-type', 'avatar' );
			updateAvatarButton.on( 'click', onClickImageButton );
			updateAvatarButton.on( 'focusout', onFocusOutImageButton );

			window.ondragover = function( e ) {
				e.preventDefault();
			};

			window.ondrop = onDrop;

			$( '#llms-sl-uploader-zone' ).on( 'dragover', function() {
				$( this ).addClass( 'dragover' );
			} ).on( 'dragout', function() {
				$( this ).removeClass( 'dragover' );
			} );

			$( '#llms-sl-upload-image' ).on( 'change', onUploadChange );

		}

		/**
		 * Handle "Delete Image" button click.
		 *
		 * @since 1.6.0
		 *
		 * @param {string} type The profile image type, 'avatar'|'cover'.
		 * @return void
		 */
		function deleteImage( type ) {

			var data = new FormData();
			data.append( 'action', 'llms_sl_delete_image' );
			data.append( 'nonce', $( '#llms-sl-profile-image-nonce' ).val() );
			data.append( 'type', type );

			request( data, type );
		}

		/**
		 * Returns true if the avatar or the cover images are the default ones.
		 *
		 * @param {string} type The profile image type, 'avatar'|'cover'.
		 * @return {boolean}
		 */
		function isCustomImage( type ) {

			switch ( type ) {
				case 'avatar':
					return isCustomAvatarImage();
				case 'cover':
					return isCustomCoverImage();
				default:
				  return false;
			}

		}

		/**
		 * Returns true if the avatar image from WordPress `get_avatar()` has been replaced by LifterLMS Social Learning.
		 *
		 * @since 1.6.0
		 *
		 * @return {boolean}
		 */
		function isCustomAvatarImage() {
			// Does the image have the added 'llms-sl-custom-avatar' class?
			return $( '#llms-avatar-img img' ).hasClass( 'llms-sl-custom-avatar' );
		}

		/**
		 * Returns true if there is a cover image that obscures the default linear gradient.
		 *
		 * @since 1.6.0
		 *
		 * @return {boolean}
		 */
		function isCustomCoverImage() {
			// Does the cover element contain zero images?
			return $( '#llms-cover-img img' ).length > 0;
		}

		/**
		 * Handle onClick events for the profile image by deciding whether to open the uploader modal
		 * or to display the context menu.
		 *
		 * @since 1.6.0
		 *
		 * @param {jQuery.Event} jqEvent {@see https://api.jquery.com/category/events/event-object/}.
		 * @return @void
		 */
		function onClickImageButton( jqEvent ) {

			let
				$target = $( jqEvent.currentTarget );
				type    = $target.attr( 'data-image-type' );

			// Is the image currently the default?
			if ( isCustomImage( type ) ) {
				// Display the context menu.
				$target.next( '.llms-sl-context-menu' ).css( 'display', 'block' );
			} else {
				// Display the uploader modal window.
				openUploaderModal( type );
			}
		}

		/**
		 * Handle drop events to allow dragging images into uploader.
		 *
		 * @since 1.0.0
		 *
		 * @param {jQuery.Event} jqEvent {@see https://api.jquery.com/category/events/event-object/}.
		 * @return   void
		 */
		function onDrop( jqEvent ) {

			jqEvent.preventDefault();

			var $target = $( jqEvent.target );

			if ( ! $target.closest( '.llms-sl-uploader' ).length ) {
				return;
			}

			upload( jqEvent.dataTransfer.files[ 0 ] );

		}

		/**
		 * Handle focusout events for the profile image.
		 *
		 * @since 1.6.0
		 *
		 * @param {jQuery.Event} jqEvent {@see https://api.jquery.com/category/events/event-object/}.
		 * @return void
		 */
		function onFocusOutImageButton( jqEvent ) {

			var type = $( jqEvent.currentTarget ).attr( 'data-image-type' );
			$( jqEvent.currentTarget ).next( '.llms-sl-context-menu' ).css( 'display', 'none' );

			switch ( $( jqEvent.relatedTarget ).attr( 'id' ) ) {
				case 'llms-sl-upload-' + type + '-image':
					openUploaderModal( type );
					break;
				case 'llms-sl-delete-' + type + '-image':
					deleteImage( type );
					break;
			}
		}

		/**
		 * Handle upload when files are added to the file uploader.
		 *
		 * @since 1.0.0
		 *
		 * @param {jQuery.Event} jqEvent {@see https://api.jquery.com/category/events/event-object/}.
		 * @return void
		 */
		function onUploadChange( jqEvent ) {

			upload( jqEvent.target.files[ 0 ] );

		}

		/**
		 * Opens the profile image uploader modal.
		 *
		 * @since 1.6.0
		 *
		 * @param {string} type The profile image type, 'avatar'|'cover'.
		 * @return void
		 */
		function openUploaderModal( type ) {

			$modal.iziModal( 'open' );
			$( '#llms-sl-uploader-zone' ).attr( 'data-upload-type', type );
		}

		/**
		 * Makes an ajax request and provides error and success handlers.
		 *
		 * @since 1.6.0
		 *
		 * @param {FormData} data The data to send.
		 * @param {string}   type The image type, 'avatar'|'cover'.
		 * @return void
		 */
		function request( data, type ) {

			var spinnerParent = 'opened' === $modal.iziModal( 'getState' ) ? $modal : $( '#llms-' + type + '-img' );

			LLMS.Ajax.call( {
				data: data,
				contentType: false,
				processData: false,
				beforeSend: function() {

					spinnerParent.find( '.llms-sl-error' ).remove();
					LLMS.Spinner.start( spinnerParent );

				},
				error: function( xhr, status, error ) {

					var msg = error;

					if ( 'object' === typeof error && error.message ) {
						msg = error.message;
					}
					show_error( msg, status, error );
					LLMS.Spinner.stop( spinnerParent );

				},
				success: function( r ) {

					LLMS.Spinner.stop( spinnerParent );
					if ( r && r.success ) {
						if ( 'avatar' === type ) {
							$( '#llms-avatar-img' ).html( r.data.image );
						} else if ( 'cover' === type ) {
							$( '#llms-cover-img' ).html( r.data.image );
						}
						$modal.iziModal( 'close' );
					} else {
						show_error( r.message, 'error', r );
					}

				}

			} );

		}

		/**
		 * Show an uploader error message.
		 *
		 * @since 1.1.0
		 * @since 1.1.0 Unknown.
		 *
		 * @param {string} msg    Error message string.
		 * @param {string} status Error status string.
		 * @param {Object} data   Additional data to log to the console.
		 * @return void
		 */
		function show_error( msg, status, data ) {

			console.error( status, data );

			msg = LLMS.l10n.translate( 'Error' ) + ': ' + msg;

			$( '#llms-sl-uploader-zone' ).append( '<p class="llms-sl-error">' + msg + '</p>' );

		}

		/**
		 * Upload files via AJAX.
		 *
		 * @since 1.0.0
		 * @since 1.1.0 Unknown.
		 * @since 1.6.0 Allow the image to be replaced by an uploaded file with the same name.
		 *              Change nonce field name from 'llms-sl-upload-nonce' to 'llms-sl-profile-image-nonce'.
		 *
		 * @param {File} file JS DOM file transfer object.
		 * @return void
		 */
		function upload( file ) {

			var type = $( '#llms-sl-uploader-zone' ).attr( 'data-upload-type' );
			var data = new FormData();

			// Prevent IE from making a 2nd request after the `#llms-sl-upload-image` value is blanked.
			if ( ! file ) {
				return;
			}

			data.append( 'action', 'llms_sl_upload_image' );
			data.append( 'image', file );
			data.append( 'nonce', $( '#llms-sl-profile-image-nonce' ).val() );
			data.append( 'type', type );

			request( data, type );

			// Reset upload image input element value so that the image can be replaced by a file with the same name.
			$( '#llms-sl-upload-image' ).val( '' );
		}

		// Go.
		bind();

		return {
			upload: upload,
		};

	};

	window.llms_sl         = window.llms_sl || {};
	window.llms_sl.profile = new Profile();

} )( jQuery );
